import React, { Component } from 'react';

//Import Component
import NavBar from "../components/NavBar";
import AboutUsComponent from "../components/AboutUsComponent";
import Footer from "../components/Footer";

class AboutUs extends Component {

  state = { 
    usename: '',
    success: false,
    registered: false,
  }

  componentDidMount() {
    this.setState({ 
      firstName: localStorage.getItem('firstName'),
      success: localStorage.getItem('success'),
      userStatus: localStorage.getItem('userStatus'),
    });

    if (window.scrollY) {
      window.scroll(0, 0);  // reset the scroll position to the top left of the document.
    }
  }
  
  render() {
    return (
        <>
            {/* NavBar: src/components/NavBar.jsx */}
            <NavBar 
              pageName="about-us" 
              firstName={this.state.firstName} 
              success={this.state.success} 
              userStatus={this.state.userStatus}
            />

            {/* BlogPost: src/components/Blog/AppointmentInfoForm.jsx */}
            <AboutUsComponent />

            {/* Footer: src/components/Footer.jsx */}
            <Footer />
        </>
    );
  }
}
export default AboutUs;
