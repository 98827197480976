import React, { Component } from 'react';
import PropTypes from "prop-types";

class Services extends Component {
  render() {
      //Service loop start
      const servicedata = this.props.servicesData.map((service, index) => (
        <div className="col-md-6 col-lg-4 text-center" key={index}>
            <div className="service-item">
                <div className="glyph">
                    <i className={service.icon} />
                </div>
                <h3>{service.heading}</h3>
                <p>{service.description}</p>
            </div>
        </div>
    ));
    //Service loop END
    return (
        <>
            <section id="services" className="services ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <div 
                                className="section-title"
                                data-aos="fade-up"
                                data-aos-delay="30"
                                data-aos-duration="1000"
                            >
                                <h2>{this.props.sectionTitle}</h2>
                                <p>{this.props.sectionDescription}</p>
                                <span className="section-title-bg">{this.props.SectionbgTitle}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {servicedata}
                    </div>
                </div>
            </section>
        </>
    );
  }
}

//Props Types
Services.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    servicesData: PropTypes.array
};

//Default Props
Services.defaultProps = {
    SectionbgTitle: "Services",
    sectionTitle: "Services",
    sectionDescription:
        "Traditional Chinese medicine (TCM) is a broad range of medicine practices sharing common concepts which have been developed in China and are based on a tradition of more than 2,000 years, including various forms of herbal medicine, acupuncture, massage (Tui Na), exercise (Qi Gong), and dietary therapy.",

    servicesData: [
        {
            icon: "icofont-stretcher",//"icofont-automation",
            heading: "Acupuncture",
            description:
                "There is no longer any doubt that acupuncture can significantly improve your health and overall sense of wellbeing."
        },
        {
            icon: "icofont-plant",
            heading: "Herbal Medicine",
            description:
                "Chinese Herbal Medicine has numerous benefis, including relief from pain, nerve damage, gall stones, and many others."
        },
        {
            icon: "icofont-doctor-alt",
            heading: "Chiropractic Care",
            description:
                "Effective and comfortable technique. Helps to keep rotations of the lumbar spine and neck down to a minimum."
        },
        {
            icon: "icofont-heart-beat-alt",
            heading: "Moxibustion",
            description:
                "Aims to stimulate the flow of Qi, strengthen the blood and generally maintain good health and wellbeing."
        },
        {
            icon: "icofont-crutch",
            heading: "Pain Relief Therapy",
            description:
                "Acupuncture is largely based on the fact that it works by both relieving the sensation of pain and resolve underlying issue."
        },
        {
            icon: "icofont-icu",
            heading: "Pre and Post Natal Therapy",
            description:
                "Therapies offered to depend on pregnant woman, each pregnant woman may receive different types of treatment."
        },
    ]
};

export default Services;
