import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";

class RegistrationForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patientId: "",
            firstName: "",
            surnames: "",
            email: "",
            // patientId: "",
            painDistribution: "",
            strong: false,
            weak: false,
            compressive: false,
            distensive: false,
            burning1: false,
            lacerating: false,
            heavy: false,
            diffuse: false,
            pulsatile: false,
            migratory: false,
            temp: null,
            pressure: null,
            burning2: false,
            coldness: false,
            heaviness: false,
            acute: false,
            chronic: false,
            fever: false,
            shakingChills: false,
            insomnia: false,
            dyspepsia: false,
            cough: false,
            diarrhea: false,
            fatigue: false,
            shortnessBreath: false,
            cramps: false,
            muscleSpasms: false,
            dizziness: false,
            palpitations: false,
            anxiety: false,
            depression: false,
            otherDisorderMoods: null,
            whenStarted: null,
            durationEvolution: null,
            factorSymptoms: null,
            successMsg: null
        };
    }

    componentDidMount() {           
        this.setState({
            patientId: localStorage.getItem('patientId'),
            firstName: localStorage.getItem('firstName'),
            surnames:  localStorage.getItem('surnames'),
            email:  localStorage.getItem('email'),
        })
    }

    handleForm = e => {
        let that = this;

        axios
            .post("http://localhost:5000/appointment", this.state )
            .then(function(response) {
                console.log(response);
                document.getElementById("contactForm").reset();
                that.setState({
                    successMsg: "Thank you! We received your message"
                });
                document.getElementById("contactForm").reset();
            })
            .catch(function(error) {});
    };
    
    handleFields = e => {
        console.log({ [e.target.name]: e.target.value })
        this.setState({ [e.target.name]: e.target.value })};

    handleCheckboxes = e => {
        console.log({ [e.target.name]: e.target.checked })
        this.setState({ [e.target.name]: e.target.checked })
    };
    
    render() {
        return (
            <>
                <section id="contact" className="contact-area ptb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2 text-center">
                                <div 
                                    className="section-title"
                                    data-aos="fade-up"
                                    data-aos-delay="30"
                                    data-aos-duration="1000"
                                >
                                    <h2>{this.props.sectionTitle}</h2>
                                    <p>{this.props.sectionDescription}</p>
                                    <span className="section-title-bg">{this.props.SectionbgTitle}</span>
                                </div>
                            </div>
                        </div>

                        <div className="row" >
                            <div className="col-lg-12 col-md-12">
                                <div className="contact-form">
                                    <form
                                        id="contactForm"
                                        onSubmit={(e, formData, inputs) => {
                                            e.preventDefault();
                                            this.handleForm(formData);
                                        }}
                                    >
                                        <div className="">
                                            <div className="row col-lg-8 py-4 ">
                                                <h3 className="text-center pb-3">General Information</h3>
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="form-group">
                                                        <input
                                                            name="firstName"
                                                            id="firstName" 
                                                            value={this.state.firstName}
                                                            disabled                                                       
                                                            className="form-control Disabled"
                                                            placeholder="First Name"
                                                            onChange={
                                                                this.handleFields
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="form-group">
                                                        <input
                                                            name="Surnames"
                                                            id="Surnames"
                                                            value={this.state.surnames}
                                                            disabled
                                                            className="form-control"
                                                            placeholder="Surnames"
                                                            onChange={
                                                                this.handleFields
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                {/* <div className="col-lg-3 col-md-3">
                                                    <div className="form-group">
                                                        <input
                                                            name="dob"
                                                            id="dob"
                                                            required
                                                            className="form-control"
                                                            placeholder="Date of Birth"
                                                            onChange={
                                                                this.handleFields
                                                            }
                                                        />
                                                    </div>
                                                </div> */}
                                           
                                                {/* <div className="col-lg-3 col-md-3">
                                                    <div className="form-group">
                                                        <input
                                                            name="age"
                                                            id="age"
                                                            required
                                                            className="form-control"
                                                            placeholder="Age"
                                                            onChange={
                                                                this.handleFields
                                                            }
                                                        />
                                                    </div>
                                                </div> */}

                                                {/* <div className="col-lg-3 col-md-3">
                                                    <div className="form-group">
                                                        <input
                                                            name="sex"
                                                            id="sex"
                                                            required
                                                            className="form-control"
                                                            placeholder="Sex"
                                                            onChange={
                                                                this.handleFields
                                                            }
                                                        />
                                                    </div>
                                                </div> */}

                                                {/* <div className="col-lg-3 col-md-3">
                                                    <div className="form-group">
                                                        <input
                                                            name="mobilePhone"
                                                            id="mobilePhone"
                                                            type="number" 
                                                            className="form-control"
                                                            placeholder="Mobile Phone"
                                                            onChange={
                                                                this.handleFields
                                                            }
                                                        />
                                                    </div>
                                                </div>    */}
                                                {/* <div className="col-lg-3 col-md-3">
                                                    <div className="form-group">
                                                        <input
                                                            name="homePhone"
                                                            id="homePhone"
                                                            type="number" 
                                                            className="form-control"
                                                            placeholder="Home Phone"
                                                            onChange={
                                                                this.handleFields
                                                            }
                                                        />
                                                    </div>
                                                </div> */}
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <input
                                                            name="email"
                                                            id="email"
                                                            value={this.state.email}
                                                            type="email"
                                                            disabled
                                                            className="form-control"
                                                            placeholder="Email"
                                                            onChange={
                                                                this.handleFields
                                                            }
                                                        />
                                                    </div>
                                                </div>                                            
                                            </div>
                                            
                                            <div className="row my-2 border" >
                                                <h3 className="py-4 text-center">Appointment Reasons</h3>
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <input
                                                            name="painDistribution"
                                                            id="pain-distribution"
                                                            required
                                                            className="form-control"
                                                            placeholder="Pain Distribution Area"
                                                            onChange={
                                                                this.handleFields
                                                            }
                                                        />
                                                    </div>
                                                </div> 
                                                <h5 className="pt-4 pb-2">Nature of Pain</h5>
                                                <div className="form-group">
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2" >
                                                        <input 
                                                            name="strong" 
                                                            id="strong"  
                                                            className="form-check-input" 
                                                            type="checkbox" 
                                                            onChange={
                                                                this.handleCheckboxes
                                                            }
                                                        />
                                                        <label className="form-check-label" htmlFor="strong">
                                                            Strong
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2">
                                                        <input 
                                                            name="weak" 
                                                            id="weak" 
                                                            className="form-check-input" 
                                                            type="checkbox"
                                                            onChange={
                                                                this.handleCheckboxes
                                                            } 
                                                        />
                                                        <label className="form-check-label" htmlFor="weak">
                                                            Weak
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2">
                                                        <input 
                                                            name="compressive" 
                                                            id="compressive" 
                                                            className="form-check-input" 
                                                            type="checkbox" 
                                                            onChange={
                                                                this.handleCheckboxes
                                                            }  
                                                        />
                                                        <label className="form-check-label" htmlFor="compressive">
                                                            Compressive 
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2">
                                                        <input 
                                                            name="distensive"  
                                                            id="distensive"  
                                                            className="form-check-input" 
                                                            type="checkbox" 
                                                            onChange={
                                                                this.handleCheckboxes
                                                            } 
                                                        />
                                                        <label className="form-check-label" htmlFor="distensive">
                                                            Distensive  
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2">
                                                        <input 
                                                            name="burning1" 
                                                            id="burning1" 
                                                            className="form-check-input" 
                                                            type="checkbox" 
                                                            onChange={
                                                                this.handleCheckboxes
                                                            }  
                                                        />
                                                        <label className="form-check-label" htmlFor="burning1">
                                                            Burning 
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2">
                                                        <input 
                                                            name="lacerating"  
                                                            id="lacerating"  
                                                            className="form-check-input" 
                                                            type="checkbox"
                                                            onChange={
                                                                this.handleCheckboxes
                                                            } 
                                                        />
                                                        <label className="form-check-label" htmlFor="lacerating">
                                                            Lacerating 
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2">
                                                        <input 
                                                            name="heavy" 
                                                            id="heavy" 
                                                            className="form-check-input" 
                                                            type="checkbox" 
                                                            onChange={
                                                                this.handleCheckboxes
                                                            }  
                                                        />
                                                        <label className="form-check-label" htmlFor="heavy">
                                                            Heavy 
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2">
                                                        <input 
                                                            name="diffuse"  
                                                            id="diffuse"  
                                                            className="form-check-input" 
                                                            type="checkbox" 
                                                            onChange={
                                                                this.handleCheckboxes
                                                            } 
                                                        />
                                                        <label className="form-check-label" htmlFor="diffuse">
                                                            Diffuse 
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2">
                                                        <input 
                                                            name="pulsatile"  
                                                            id="pulsatile"  
                                                            className="form-check-input" 
                                                            type="checkbox" 
                                                            onChange={
                                                                this.handleCheckboxes
                                                            } 
                                                        />
                                                        <label className="form-check-label" htmlFor="pulsatile">
                                                            Pulsatile 
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2">
                                                        <input 
                                                            name="migratory"  
                                                            id="migratory"  
                                                            className="form-check-input" 
                                                            type="checkbox" 
                                                            onChange={
                                                                this.handleCheckboxes
                                                            } 
                                                        />
                                                        <label className="form-check-label" htmlFor="migratory">
                                                            Migratory 
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>   

                                            <div className="row pb-4 border">
                                                <h5 className="pt-4 pb-2">Way of Pain Relief</h5>
                                                <div className="">
                                                    <h6 className="form-check-inline" style={{width: "7rem"}}>Temperature:</h6>
                                                    <div className="form-check form-check-inline">
                                                        <input 
                                                            name="temp" 
                                                            id="cold-temperature" 
                                                            className="form-check-input" 
                                                            type="radio" 
                                                            value="1" 
                                                            onChange={
                                                                this.handleFields
                                                            } 
                                                        />
                                                        <label className="form-check-label" style={{width: "3rem"}} htmlFor="cold-temperature">
                                                            Cold
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input 
                                                            name="temp" 
                                                            id="warm-temperature" 
                                                            className="form-check-input" 
                                                            type="radio" 
                                                            value="2" 
                                                            onChange={
                                                                this.handleFields
                                                            } 
                                                        />
                                                        <label className="form-check-label" htmlFor="warm-temperature">
                                                           Warm
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <h6 className="form-check-inline" style={{width: "7rem"}}>Pressure:</h6>
                                                    <div className="form-check form-check-inline">
                                                        <input 
                                                            name="pressure" 
                                                            id="light-presure" 
                                                            className="form-check-input" 
                                                            type="radio" 
                                                            value="1" 
                                                            onChange={
                                                                this.handleFields
                                                            }
                                                        />
                                                        <label className="form-check-label" style={{width: "3rem"}} htmlFor="light-presure">
                                                            Light
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input 
                                                            name="pressure" 
                                                            id="intense-presure" 
                                                            className="form-check-input" 
                                                            type="radio" 
                                                            value="2" 
                                                            onChange={
                                                                this.handleFields
                                                            }
                                                        />
                                                        <label className="form-check-label" htmlFor="intense-presure">
                                                            Intense
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="row my-2 border">
                                                <h5 className="pt-4 pb-2">Accompanying Symptoms of Pain</h5>                                          
                                                <div className="col-lg-12 col-md-12 pb-4">
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2" >
                                                        <input 
                                                            name="burning2" 
                                                            id="burning2" 
                                                            className="form-check-input" 
                                                            type="checkbox" 
                                                            onChange={
                                                                this.handleCheckboxes
                                                            } 
                                                        />
                                                        <label className="form-check-label" htmlFor="burning2">
                                                            Burning
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2">
                                                        <input 
                                                            name="coldness" 
                                                            id="coldness" 
                                                            className="form-check-input" 
                                                            type="checkbox" 
                                                            onChange={
                                                                this.handleCheckboxes
                                                            } 
                                                        />
                                                        <label className="form-check-label" htmlFor="coldness">
                                                            Coldness
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2">
                                                        <input 
                                                        name="heaviness"  
                                                        id="heaviness"  
                                                        className="form-check-input" 
                                                        type="checkbox" 
                                                        onChange={
                                                            this.handleCheckboxes
                                                        } 
                                                    />
                                                        <label className="form-check-label" htmlFor="heaviness">
                                                            Heaviness 
                                                        </label>
                                                    </div>
                                                </div>                          
                                            </div>

                                            <div className="row my-2 border">
                                                <h5 className="pt-4 pb-2">Evolution</h5>                                          
                                                <div className="col-lg-12 col-md-12 pb-4">                                                
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2" >
                                                        <input 
                                                            name="acute" 
                                                            id="acute" 
                                                            className="form-check-input" 
                                                            type="checkbox" 
                                                            onChange={
                                                                this.handleCheckboxes
                                                            } 
                                                        />
                                                        <label className="form-check-label" htmlFor="acute">
                                                            Acute
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2">
                                                        <input 
                                                            name="chronic" 
                                                            id="chronic" 
                                                            className="form-check-input" 
                                                            type="checkbox" 
                                                            onChange={
                                                                this.handleCheckboxes
                                                            } 
                                                        />
                                                        <label className="form-check-label" htmlFor="chronic">
                                                            Chronic
                                                        </label>
                                                    </div>                                                    
                                                </div>                          
                                            </div>

                                            <div className="row my-2 border" >
                                                <h5 className="pt-4 pb-2">Others</h5>
                                                <div className="form-group ">
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2" >
                                                        <input 
                                                            name="fever"
                                                            id="fever"
                                                            className="form-check-input" 
                                                            type="checkbox" 
                                                            onChange={
                                                                this.handleCheckboxes
                                                            }  
                                                        />
                                                        <label className="form-check-label" htmlFor="fever">
                                                            Fever
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2">
                                                        <input 
                                                            name="shakingChills"
                                                            id="shaking-chills"
                                                            className="form-check-input" 
                                                            type="checkbox" 
                                                            onChange={
                                                                this.handleCheckboxes
                                                            }  
                                                        />
                                                        <label className="form-check-label" htmlFor="shaking-chills">
                                                            Shaking Chills
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2">
                                                        <input 
                                                            name="insomnia"
                                                            id="insomnia"
                                                            className="form-check-input" 
                                                            type="checkbox" 
                                                            onChange={
                                                                this.handleCheckboxes
                                                            } 
                                                        />
                                                        <label className="form-check-label" htmlFor="insomnia">
                                                            Insomnia 
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2">
                                                        <input 
                                                            name="dyspepsia" 
                                                            id="dyspepsia" 
                                                            className="form-check-input" 
                                                            type="checkbox" 
                                                            onChange={
                                                                this.handleCheckboxes
                                                            } 
                                                        />
                                                        <label className="form-check-label" htmlFor="dyspepsia">
                                                            Dyspepsia  
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2">
                                                        <input 
                                                            name="cough"
                                                            id="cough"
                                                            className="form-check-input" 
                                                            type="checkbox" 
                                                            onChange={
                                                                this.handleCheckboxes
                                                            } 
                                                        />
                                                        <label className="form-check-label" htmlFor="cough">
                                                            Cough 
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2">
                                                        <input 
                                                            name="diarrhea" 
                                                            id="diarrhea" 
                                                            className="form-check-input" 
                                                            type="checkbox" 
                                                            onChange={
                                                                this.handleCheckboxes
                                                            }  
                                                        />
                                                        <label className="form-check-label" htmlFor="diarrhea">
                                                            Diarrhea 
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2">
                                                        <input 
                                                            name="fatigue"  
                                                            id="fatigue"  
                                                            className="form-check-input" 
                                                            type="checkbox" 
                                                            onChange={
                                                                this.handleCheckboxes
                                                            } 
                                                        />
                                                        <label className="form-check-label" htmlFor="fatigue">
                                                            Fatigue 
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2">
                                                        <input 
                                                            name="shortnessBreath"
                                                            id="shortness-breath"
                                                            className="form-check-input" 
                                                            type="checkbox" 
                                                            onChange={
                                                                this.handleCheckboxes
                                                            } 
                                                        />
                                                        <label className="form-check-label" htmlFor="shortness-breath">
                                                            Shortness of Breath 
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2">
                                                        <input 
                                                            name="cramps"
                                                            id="cramps"
                                                            className="form-check-input" 
                                                            type="checkbox" 
                                                            onChange={
                                                                this.handleCheckboxes
                                                            }   
                                                        />
                                                        <label className="form-check-label" htmlFor="cramps">
                                                            Cramps 
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2">
                                                        <input 
                                                            name="muscleSpasms"
                                                            id="muscle-spasms"
                                                            className="form-check-input" 
                                                            type="checkbox" 
                                                            onChange={
                                                                this.handleCheckboxes
                                                            } 
                                                        />
                                                        <label className="form-check-label" htmlFor="muscle-spasms">
                                                            Muscle Spasms 
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2">
                                                        <input 
                                                            name="dizziness"
                                                            id="dizziness"
                                                            className="form-check-input" 
                                                            type="checkbox" 
                                                            onChange={
                                                                this.handleCheckboxes
                                                            } 
                                                        />
                                                        <label className="form-check-label" htmlFor="dizziness">
                                                            Dizziness 
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2">
                                                        <input 
                                                            name="palpitations" 
                                                            id="palpitations" 
                                                            className="form-check-input" 
                                                            type="checkbox" 
                                                            onChange={
                                                                this.handleCheckboxes
                                                            }  
                                                        />
                                                        <label className="form-check-label" htmlFor="palpitations">
                                                            Palpitations
                                                        </label>
                                                    </div>
                                                    <div className="col-lg-8 col-md-8 pt-3">
                                                        <h6 className="pb-2" >Mood Disorders:</h6>
                                                        <div className="form-check form-check-inline col-lg-3 col-md-3">
                                                            <input 
                                                                name="anxiety" 
                                                                id="anxiety" 
                                                                className="form-check-input"
                                                                type="checkbox" 
                                                                onChange={
                                                                    this.handleCheckboxes
                                                                }  
                                                            />
                                                            <label className="form-check-label" htmlFor="anxiety">
                                                                Anxiety
                                                            </label>
                                                        </div>
                                                    <div className="form-check form-check-inline col-lg-3 col-md-3">
                                                        <input 
                                                            name="depression" 
                                                            id="depression" 
                                                            className="form-check-input" 
                                                            type="checkbox" 
                                                            onChange={
                                                                this.handleCheckboxes
                                                            }
                                                        />
                                                        <label className="form-check-label" htmlFor="depression">
                                                            Depression
                                                        </label>
                                                    </div>
                                                    <div className="">
                                                        <label className="pt-3 form-label" htmlFor="others">
                                                            Others
                                                        </label> 
                                                        <input 
                                                            name="otherDisorderMoods" 
                                                            id="other-disorder-moods" 
                                                            className="form-control col-lg-6 col-md-6"
                                                            type="text" 
                                                            onChange={
                                                                this.handleFields
                                                            }
                                                        />
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>  

                                            <div className="row pt-4 border">
                                                <h4 className="text-center">History Current Illness</h4>
                                                <h5 className="py-4">Reason for Consultation</h5>
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="whenStarted">
                                                            When did it start?
                                                        </label>
                                                        <input
                                                            name="whenStarted"
                                                            id="when-started"
                                                            className="form-control"
                                                            type="text" 
                                                            onChange={
                                                                this.handleFields
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="whenStarted">
                                                            Form of Installation, Duration and Evolution
                                                        </label>
                                                        <input
                                                            name="durationEvolution"
                                                            id="duration-evolution"
                                                            type="text" 
                                                            className="form-control"
                                                            onChange={
                                                                this.handleFields
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-check-label" htmlFor="factors-symptoms">
                                                            Precipitating Factors and Associated Symptoms
                                                        </label>
                                                        <input
                                                            name="factorSymptoms"
                                                            id="factor-symptoms"
                                                            type="text" 
                                                            className="form-control"
                                                            onChange={
                                                                this.handleFields
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="center-wrap mt-4" >
                                            <div className="button ">
                                                <button type="submit">Submit <i className="icofont-long-arrow-right"/> </button>
                                                <div className="mask"></div>
                                            </div>
                                        </div>                                        

                                        <div className="clearfix" />
                                    </form>

                                    {this.state.successMsg !== "" ? (
                                        <div className="col-md-12"> 
                                            <div id="contact_send_status">
                                                <h3 className="contactMsg">
                                                    {this.state.successMsg}
                                                </h3>
                                            </div>
                                         </div>
                                    ) : null}

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

//Props Types
RegistrationForm.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    AddTitle: PropTypes.string,
    Address: PropTypes.string,
    EmailTitle: PropTypes.string,
    Email: PropTypes.string,
    PhoneTitle: PropTypes.string,
    Phone: PropTypes.string,
};

//Default Props
RegistrationForm.defaultProps = {
    SectionbgTitle: "Appoitment",
    sectionTitle: "Appointment Information",
    sectionDescription: "Please submit the form before your first appointment.",
    AddTitle: "Address",
    Address: "2750 Quadra Street Victoria, Canada.",
    EmailTitle: "Email",
    Email: "neningerorientalmedicine@gmail.com",
    PhoneTitle: "Phone",
    Phone: "+1-325-555-0156",
};
export default RegistrationForm;
