import React, { Component } from "react";
import Accordion from 'react-bootstrap/Accordion';

class FAQ extends Component {
  render() {
    return (
      <>
        <section className="faq ptb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2 text-center">
                <div
                  className="section-title"
                  data-aos="fade-up"
                  data-aos-delay="30"
                  data-aos-duration="1000"
                >
                  <h2>Frequently Asked Questions</h2>
                  <p>We are willing to answer your concerns and that you understand everything about traditional medicine.</p>
                  <span className="section-title-bg">
                    FAQ
                  </span>
                </div>
              </div>
            </div>

            <div className="faq-content">
              <div className="row align-items-center">
                <div className="col-lg-4">
                  <a href="mailto:neningerorientalmedicine@gmail.com">
                    <div className="content-box color-effect-1">
                      <h3>One More Question?</h3>

                      <div className="box-icon-wrap box-icon-effect-1 box-icon-effect-1a">
                        <div className="box-icon">
                          <i className="icofont-share-alt" />
                        </div>
                      </div>

                      <p>If you have more questions, send us a message and we will answer you as soon as possible.</p>
                    </div>
                  </a>
                </div>

                <div className="col-lg-8">
                  <Accordion defaultActiveKey="0">
                    
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>How long do treatments take?</Accordion.Header>
                      <Accordion.Body>
                        The usual acupuncture session lasts about half an hour, though this may vary slightly depending on your condition. The first diagnostic session will take longer – about an hour – since we will be asking you questions and carrying out the physical exam before starting treatment.
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Does acupuncture relieve pain?</Accordion.Header>
                      <Accordion.Body>
                        Pain is always an indicator that something is wrong with our bodies. Chronic pain, in particular, is often the major reason why Western people seek acupuncture, particularly after they have spent some time taking medications that mask the pain but do not resolve the underlying issue. There are many types of pain including different levels of aches (such as headache, tooth ache) etc, throbbing pain, stabbing pains, sensation of pressure, mental anguish, etc. Acupuncture’s world-wide reputation is largely based on the fact that it works by both relieving the sensation of pain and at the same time resolving the underlying issue that is causing the discomfort in the first place.
                      </Accordion.Body>
                    </Accordion.Item>

                    

                    <Accordion.Item eventKey="2">
                      <Accordion.Header>How many treatments and how often?</Accordion.Header>
                      <Accordion.Body>
                        There is no set treatment time. This always varies depending on the patient and the condition that is being treated. Usually at first we recommend 2-4 treatments per week and a minimum of 8-16 sessions in all. Acute conditions tend to respond more quickly than chronic conditions – sometimes after only one or two treatments. But everybody is different and you may need to continue with your acupuncture for several months before noting any change. There are even patients who do not respond, despite the practitioner’s skill and efforts.
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="3">
                      <Accordion.Header>Are Chinese herbs effective like western drugs?</Accordion.Header>
                      <Accordion.Body>
                         Yes. Many conditions treated by western drugs can also be successfully treated with herbal medicine. Examples include: hypertension, diabetes, headaches and pain conditions, fibromyalgia, allergies and sinus infections, acid reflux disease, bowel problems (IBS and Crohn’s disease), bladder infections, skin conditions, menstrual cramping, peri/ postmenopausal syndrome, parkinson’s disease, insomnia, anxiety, depression and so on..
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default FAQ;
