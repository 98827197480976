import React, { Component } from "react";
// import {  Redirect } from "react-router-dom"
import PropTypes from "prop-types";
import axios from "axios";
import SuccessSubmit from "./SuccessSubmit"
import Cookies from "universal-cookie";

const cookies = new Cookies();

// import cookieParser from "cookie-parser";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "", 
            success: false,
            registered: false,             
            successMsg: "",
            wrongInfoMsg: "",
            token: cookies.get('token'),
        };
    }

    handleForm = e => {
        let that = this;
        localStorage.clear();

        axios
            .post("http://localhost:5000/login3", this.state ) //"https://apinom.ittaylor.com/login"
            .then(function(response) {
                console.log(response.data);
                console.log('token', response.data.token);
                console.log('User', response.data.user );
                console.log(response);
                if(response.data.success) {
                    cookies.set('token', response.data.token);

                    localStorage.setItem('token', response.data.token);
                    localStorage.setItem('success', true);
                    localStorage.setItem('patientId', response.data.user.patient_id);
                    localStorage.setItem('firstName', response.data.user.first_name);
                    localStorage.setItem('surnames', response.data.user.surnames);
                    localStorage.setItem('email', response.data.user.email);
                   
                    if(response.data.userStatus === "registered") {
                        that.setState({
                            success: true,
                            registered: true,
                        });
                        localStorage.setItem('userStatus', 'registered');
                        window.location.href = '/home';
                    } else if(response.data.userStatus === "exist") {
                        that.setState({
                            success: true
                        });
                        localStorage.setItem('userStatus', 'exist');
                        window.location.href = '/general-info';
                    }
                } else {
                    document.getElementById("contactForm").reset();
                    that.setState({
                        success: false,
                        wrongInfoMsg: "Your email or password is not correct!!!",
                    });
                    document.getElementById("contactForm").reset(); 
                }
            })
            .catch(function(error) {
                // document.getElementById("contactForm").reset();
                console.log(error)
                that.setState({
                    success: false,
                    wrongInfoMsg: "Something went wrong!!!",
                });
                // document.getElementById("contactForm").reset(); 
            });
    };

    handleFields = e => this.setState({ [e.target.name]: e.target.value });
    
    render() {
        return (
            <> {this.state.success ? (
                <section>
                    { this.state.registered 
                        ? <SuccessSubmit link={"/"} sectionDescription={"Welcome to Neninger Oriental Medicine"} />
                        : <SuccessSubmit link={"/general-info"} sectionDescription={"Please enter your general information"} />
                    }
                    
                </section>
            ) : (
                <section id="contact" className="contact-area ptb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2 text-center">
                                <div 
                                    className="section-title"
                                    data-aos="fade-up"
                                    data-aos-delay="30"
                                    data-aos-duration="1000"
                                >
                                    <h2>{this.props.sectionTitle}</h2>
                                    <p>{this.props.sectionDescription}</p>
                                    <span className="section-title-bg">{this.props.SectionbgTitle}</span>
                                </div>
                            </div>
                        </div>

                        <div className="row" >
                            <div className="col-lg-6 offset-lg-3 text-center">
                                <div className="contact-form">
                                    <form
                                        id="contactForm"
                                        onSubmit={(e, formData, inputs) => {
                                            e.preventDefault();
                                            this.handleForm(formData);
                                        }}
                                    >
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <input
                                                        name="email"
                                                        id="email"
                                                        required
                                                        className="form-control"
                                                        placeholder="email"
                                                        onChange={
                                                            this.handleFields
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <input
                                                        name="password"
                                                        id="password"
                                                        type="password"
                                                        required
                                                        className="form-control"
                                                        placeholder="password"
                                                        onChange={
                                                            this.handleFields
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="center-wrap">
                                            <div className="button">
                                                <button type="submit">Submit <i className="icofont-long-arrow-right"/> </button>
                                                <div className="mask"></div>
                                            </div>
                                        </div>
                                        
                                        <div className="clearfix" />
                                    </form>
                                    {this.state.wrongInfoMsg !== "" ? (
                                        <div className="col-md-12"> 
                                            <div id="contact_send_status">
                                                {/* contactMsg */}
                                                <h3 className="errorMsg">
                                                    {this.state.wrongInfoMsg}
                                                </h3>
                                            </div>
                                         </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}</>
        );
    }
}

//Props Types
Login.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
};

//Default Props
Login.defaultProps = {
    SectionbgTitle: "Login",
    sectionTitle: "Login",
    sectionDescription: "Welcome to Neninger Oriental Medicine",
};
export default  Login;
