import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import moment from "moment";

const phoneRegex = /^(\(\d{3}\)|\d{3})-?\d{3}-?\d{4}$/

class AppointmentDocForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patientId: 43,
            firstName: "",
            surnames: "",
            dob: "",
            age: "",
            gender: "",
            email: "",
            mobilePhone: "",
            homePhone: "",
            painDistribution: "",
            strong: false,
            weak: false,
            compressive: false,
            distensive: false,
            burning1: false,
            lacerating: false,
            heavy: false,
            diffuse: false,
            pulsatile: false,
            migratory: false,
            temp: null,
            pressure: null,
            burning2: false,
            coldness: false,
            heaviness: false,
            acute: false,
            chronic: false,
            fever: false,
            shakingChills: false,
            insomnia: false,
            dyspepsia: false,
            cough: false,
            diarrhea: false,
            fatigue: false,
            shortnessBreath: false,
            cramps: false,
            muscleSpasms: false,
            dizziness: false,
            palpitations: false,
            anxiety: false,
            depression: false,
            otherDisorderMoods: null,
            whenStarted: null,
            durationEvolution: null,
            factorSymptoms: null,
            successMsg: null,
            check: 'icofont-tick-boxed', //icofont-tick-mark
            unChecked: "icofont-close-line-squared-alt",
        };
    }

    componentDidMount() {           
        let that = this;
        const paramPatientId = localStorage.getItem('patientId');
        // console.log('this.state.patientId', paramPatientId);

        axios
            .get("http://localhost:5000/get-appointment/" + paramPatientId )
            .then(function(response) {
                console.log(response.data[0]);
                // console.log("firstName:", response.data[0].pain_distribution,);
                // document.getElementById("contactForm").reset();
                that.setState({
                    firstName: response.data[0].first_name,
                    surnames:  response.data[0].surnames,
                    dob:  moment(response.data[0].dob).format("MMMM D, YYYY"),
                    age:  response.data[0].age,
                    gender:  response.data[0].gender,
                    mobilePhone: phoneRegex.exec(response.data[0].mobile_phone),
                    homePhone:  response.data[0].home_phone,
                    email:  response.data[0].email,
                    painDistribution: response.data[0].pain_distribution,
                    strong: response.data[0].strong,
                    weak: response.data[0].weak,
                    compressive: response.data[0].compressive,
                    distensive: response.data[0].distensive,
                    temp: response.data[0].temp,
                    pressure: response.data[0].pressure,
                    burning2: response.data[0].burning2,
                    coldness: response.data[0].coldness,
                    heaviness: response.data[0].heaviness,
                    acute: response.data[0].acute,
                    chronic: response.data[0].chronic,
                    fever: response.data[0].fever,
                    shakingChills: response.data[0].shaking_chills,
                    insomnia: response.data[0].insomnia,
                    dyspepsia: response.data[0].dyspepsia,
                    cough: response.data[0].cough,
                    diarrhea: response.data[0].diarrhea,
                    fatigue: response.data[0].fatigue,
                    shortnessBreath: response.data[0].shortness_breath,
                    cramps: response.data[0].cramps,
                    muscleSpasms: response.data[0].muscle_spasms,
                    dizziness: response.data[0].dizziness,
                    palpitations: response.data[0].palpitations,
                    anxiety: response.data[0].anxiety,
                    depression: response.data[0].depression,
                    otherDisorderMoods: response.data[0].other_disorder_moods,
                    whenStarted: response.data[0].when_started,
                    durationEvolution: response.data[0].duration_evolution,
                    factorSymptoms: response.data[0].factors_symptoms,

                   
                });
                // document.getElementById("contactForm").reset();
            })
            .catch(function(error) {});
            // console.log("firstName2:", this.state.strong);
    }

    // handleForm = e => {
    //     let that = this;

    //     axios
    //         .get("http://localhost:5000/get-appointment", this.state )
    //         .then(function(response) {
    //             console.log(response);
    //             document.getElementById("contactForm").reset();
    //             that.setState({
    //                 successMsg: "Thank you! We received your message"
    //             });
    //             document.getElementById("contactForm").reset();
    //         })
    //         .catch(function(error) {});
    // };
    
    handleFields = e => {
        console.log({ [e.target.name]: e.target.value })
        this.setState({ [e.target.name]: e.target.value })};

    handleCheckboxes = e => {
        console.log({ [e.target.name]: e.target.checked })
        this.setState({ [e.target.name]: e.target.checked })
    };
    
    render() {

        return (
            <>
                <section id="contact" className="contact-area ptb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2 text-center">
                                <div 
                                    className="section-title"
                                    data-aos="fade-up"
                                    data-aos-delay="30"
                                    data-aos-duration="1000"
                                >
                                    <h2>{this.props.sectionTitle}</h2>
                                    <p>{this.props.sectionDescription}</p>
                                    <span className="section-title-bg">{this.props.SectionbgTitle}</span>
                                </div>
                            </div>
                        </div>

                        <div className="row" >
                            <div className="col-lg-12 col-md-12">
                                <div className="">{/* contact-form */}
                                        <div className="">
                                            <div className="row col-lg-8 py-4 ">
                                                <h3 className="text-center pb-3">General Information</h3>
                                                <div className="col-lg-6 col-md-6">
                                                    <p className="form-control ">First Name: {this.state.firstName}</p>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <p className="form-control">Surnames: {this.state.surnames}</p>
                                                </div>
                                                <div className="col-lg-3 col-md-3">
                                                    <p className="form-control" >Date of Birth: {this.state.dob}</p>
                                                </div>
                                                <div className="col-lg-3 col-md-3">
                                                    <p className="form-control">Age: {this.state.age}</p>
                                                </div>
                                                <div className="col-lg-3 col-md-3">
                                                    <p className="form-control">
                                                        Gender: 
                                                        { this.state.gender !== 3 
                                                            ? this.state.gender === 1
                                                                ? " Male"
                                                                : " Female"
                                                            : " Prefer not to say"
                                                        }</p>
                                                </div>
                                                <div className="col-lg-3 col-md-3">
                                                    <p className="form-control">Mobile Phone: {this.state.mobilePhone}</p>
                                                </div>   
                                                <div className="col-lg-3 col-md-3">
                                                    <p className="form-control">Home Phone: {this.state.homePhone}</p>
                                                </div>
                                                <div className="col-lg-9 col-md-9">
                                                    <p className="form-control">Email: {this.state.email}</p>
                                                </div>                                            
                                            </div>
                                            
                                            <div className="row my-2 border" >
                                                <h3 className="py-4 text-center">Appointment Reasons</h3>
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <p className="form-control">Pain Distribution Area: {this.state.painDistribution} </p>
                                                    </div>
                                                </div> 
                                                <h5 className="pt-4 pb-2">Nature of Pain</h5>
                                                <div className="form-group">
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2" >
                                                        <p> { this.state.strong ?  <i className={this.state.check}/> : <i className={this.state.unChecked}/> } Strong</p>                                                        
                                                    </div>
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2">
                                                        <p> { this.state.weak ?  <i className={this.state.check}/> : <i className={this.state.unChecked}/> } Weak</p>  
                                                    </div>
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2">
                                                    <p> { this.state.compressive  ?  <i className={this.state.check}/> : <i className={this.state.unChecked}/> } Compressive </p>
                                                    </div>
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2">
                                                        <p> { this.state.distensive  ?  <i className={this.state.check}/> : <i className={this.state.unChecked}></i> } Distensive </p>
                                                    </div>
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2">
                                                        <p> { this.state.burning1  ?  <i className={this.state.check}/> : <i className={this.state.unChecked}></i> } Burning </p>
                                                    </div>
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2">
                                                        <p> { this.state.lacerating  ?  <i className={this.state.check}/> : <i className={this.state.unChecked}></i> } Lacerating  </p>
                                                    </div>
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2">
                                                        <p> { this.state.heavy  ?  <i className={this.state.check}/> : <i className={this.state.unChecked}></i> } Heavy  </p>
                                                    </div>
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2">
                                                        <p> { this.state.diffuse  ?  <i className={this.state.check}/> : <i className={this.state.unChecked}></i> } Diffuse  </p>
                                                    </div>
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2">
                                                        <p> { this.state.pulsatile  ?  <i className={this.state.check}/> : <i className={this.state.unChecked}></i> } Pulsatile  </p>
                                                        </div>
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2">
                                                        <p> { this.state.migratory  ?  <i className={this.state.check}/> : <i className={this.state.unChecked}></i> } Migratory  </p>
                                                    </div>
                                                </div>
                                            </div>   

                                            <div className="row pb-4 border">
                                                <h5 className="pt-4 pb-2">Way of Pain Relief</h5>
                                                <div className="">
                                                    <h6 className="form-check-inline" style={{width: "5rem"}} >Temperature:</h6>
                                                    <div className="form-check form-check-inline" style={{width: "9rem"}}>
                                                        <p>   
                                                        { this.state.temp !== null 
                                                            ? this.state.gender === 1
                                                                ? "Cold"
                                                                : "Warm"
                                                            : "- None -"
                                                        }</p>
                                                    </div>
                                                    <h6 className="form-check-inline" style={{width: "3rem"}}>Pressure:</h6>
                                                    <div className="form-check form-check-inline">
                                                        <p>
                                                        { this.state.pressure !== null 
                                                            ? this.state.gender === 1
                                                                ? " Light"
                                                                : " Intense"
                                                            : "- None -"
                                                        }
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="row my-2 border">
                                                <h5 className="pt-4 pb-2">Accompanying Symptoms of Pain</h5>                                          
                                                <div className="col-lg-12 col-md-12 pb-4">
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2" >
                                                        <p> { this.state.burning2  ?  <i className={this.state.check}/> : <i className={this.state.unChecked}></i> } Burning  </p>
                                                    </div>
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2">
                                                        <p> { this.state.coldness  ?  <i className={this.state.check}/> : <i className={this.state.unChecked}></i> } Coldness  </p>
                                                    </div>
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2">
                                                        <p> { this.state.heaviness  ?  <i className={this.state.check}/> : <i className={this.state.unChecked}></i> } Heaviness  </p>
                                                    </div>
                                                </div>                          
                                            </div>

                                            <div className="row my-2 border">
                                                <h5 className="form-check-inline pt-4 pb-2 ">Evolution: </h5>
                                                <div className="col-lg-6 col-md-6 pb-4">                                                
                                                    <div className="form-check form-check-inline col-lg-3 col-md-3" >
                                                        <p> { this.state.acute  ?  <i className={this.state.check}/> : <i className={this.state.unChecked}></i> } Acute  </p>
                                                    </div>
                                                    <div className="form-check form-check-inline col-lg-3 col-md-3">
                                                        <p> { this.state.chronic  ?  <i className={this.state.check}/> : <i className={this.state.unChecked}></i> } Chronic  </p>
                                                    </div>                                                    
                                                </div>                          
                                            </div>

                                            <div className="row my-2 border" >
                                                <h5 className="pt-4 pb-2">Others</h5>
                                                <div className="form-group ">
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2" >
                                                        <p> { this.state.fever  ?  <i className={this.state.check}/> : <i className={this.state.unChecked}></i> } Fever  </p>
                                                    </div>
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2">
                                                        <p> { this.state.shakingChills  ?  <i className={this.state.check}/> : <i className={this.state.unChecked}></i> }  Shaking Chills  </p>
                                                    </div>
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2">
                                                        <p> { this.state.insomnia  ?  <i className={this.state.check}/> : <i className={this.state.unChecked}></i> }  Insomnia  </p>
                                                    </div>
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2">
                                                        <p> { this.state.dyspepsia  ?  <i className={this.state.check}/> : <i className={this.state.unChecked}></i> }  Dyspepsia  </p>
                                                    </div>
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2">
                                                        <p> { this.state.cough  ?  <i className={this.state.check}/> : <i className={this.state.unChecked}></i> }  Cough  </p>
                                                    </div>
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2">
                                                        <p> { this.state.diarrhea  ?  <i className={this.state.check}/> : <i className={this.state.unChecked}></i> }  Diarrhea  </p>
                                                    </div>
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2">
                                                        <p> { this.state.fatigue  ?  <i className={this.state.check}/> : <i className={this.state.unChecked}></i> }  Fatigue  </p>
                                                    </div>
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2">
                                                        <p> { this.state.shortnessBreath  ?  <i className={this.state.check}/> : <i className={this.state.unChecked}></i> }  Shortness of Breath   </p>
                                                    </div>
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2">
                                                        <p> { this.state.cramps  ?  <i className={this.state.check}/> : <i className={this.state.unChecked}></i> } Cramps</p>
                                                    </div>
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2">
                                                        <p> { this.state.muscleSpasms  ?  <i className={this.state.check}/> : <i className={this.state.unChecked}></i> } Muscle Spasms</p>
                                                    </div>
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2">
                                                        <p> { this.state.dizziness  ?  <i className={this.state.check}/> : <i className={this.state.unChecked}></i> } Dizziness</p>
                                                    </div>
                                                    <div className="form-check form-check-inline col-lg-2 col-md-2">
                                                        <p> { this.state.palpitations  ?  <i className={this.state.check}/> : <i className={this.state.unChecked}></i> } Palpitations</p>
                                                    </div>
                                                    <div className="col-lg-8 col-md-8 pt-3">
                                                        <h6 className="pb-2" >Mood Disorders:</h6>
                                                        <div className="form-check form-check-inline col-lg-3 col-md-3">
                                                            <p> { this.state.anxiety  ?  <i className={this.state.check}/> : <i className={this.state.unChecked}></i> } Anxiety</p>
                                                        </div>
                                                    <div className="form-check form-check-inline col-lg-3 col-md-3">
                                                        <p> { this.state.depression  ?  <i className={this.state.check}/> : <i className={this.state.unChecked}></i> } Depression</p>
                                                    </div>
                                                    <div className="">
                                                        <label className="pt-3 form-label" htmlFor="others">
                                                            Others
                                                        </label> 
                                                        <p> { this.state.otherDisorderMoods === null  ?  "- None --" : this.state.otherDisorderMoods } </p>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>  

                                            <div className="row pt-4 border">
                                                <h4 className="text-center">History Current Illness</h4>
                                                <h5 className="py-4">Reason for Consultation</h5>
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="whenStarted">
                                                            When did it start?
                                                        </label>
                                                        <p> { this.state.whenStarted === null  ?  "- None --" : this.state.whenStarted } </p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="whenStarted">
                                                            Form of Installation, Duration and Evolution
                                                        </label>
                                                        <p> { this.state.durationEvolution === null  ?  "- None --" : this.state.durationEvolution } </p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-check-label" htmlFor="factors-symptoms">
                                                            Precipitating Factors and Associated Symptoms
                                                        </label>
                                                        <p> { this.state.factorSymptoms === null  ?  "- None --" : this.state.factorSymptoms } </p>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="center-wrap mt-4" >
                                            <div className="button ">
                                                <button type="submit">Submit <i className="icofont-long-arrow-right"/> </button>
                                                <div className="mask"></div>
                                            </div>
                                        </div>                                        

                                        <div className="clearfix" />
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

//Props Types
AppointmentDocForm.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    AddTitle: PropTypes.string,
    Address: PropTypes.string,
    EmailTitle: PropTypes.string,
    Email: PropTypes.string,
    PhoneTitle: PropTypes.string,
    Phone: PropTypes.string,
};

//Default Props
AppointmentDocForm.defaultProps = {
    SectionbgTitle: "Appoitment",
    sectionTitle: "Appointment Document",
    sectionDescription: "Please submit the form before your first appointment.",
    AddTitle: "Address",
    Address: "2750 Quadra Street Victoria, Canada.",
    EmailTitle: "Email",
    Email: "neningerorientalmedicine@gmail.com",
    PhoneTitle: "Phone",
    Phone: "+1-325-555-0156",
};
export default AppointmentDocForm;
