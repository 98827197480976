import React, { Component } from 'react';
import PropTypes from "prop-types";

class AboutUsComponent extends Component {

    render() {
        return (
            <>
                <section id="about-us" className="about-us pt-100 pb-70">
                    <div className="container "  >
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2 text-center">
                                <div 
                                    className="section-title"
                                    data-aos="fade-up"
                                    data-aos-delay="30"
                                    data-aos-duration="1000"
                                >
                                    <h2> {this.props.sectionTitle}</h2>
                                    <p>{this.props.sectionDescription}</p>
                                    <span className="section-title-bg">{this.props.SectionbgTitle}</span>
                                </div>
                            </div>
                        </div>
                       
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-10" >
                                <div className="about-us-info">
                                    <h3><i className="icofont-briefcase" />Work History </h3>
                                    <h4>Head Chinese Medicine Professor <span>Nov 2003 - Feb 2017</span></h4>
                                    <p>Colegio De Medicos-Cirujanos De Puerto Rico, San Juan, Puerto Rico</p>
                                    <ul>
                                        <li>Introduced and developed Chinese Medicine in Puerto Rico</li>
                                        <li>Created course and study plan that allowed trainees to get a good balance of theoretical and practical skills</li>
                                        <li>Led and coached team of professors</li>
                                        <li>Evaluated students' achievements through written assignments and practical experiences.</li>
                                        <li>Around 500 physicians graduated from the program.</li>
                                    </ul>

                                    <h4>Executive Director, CUMIC <span>Jul 2006 - Mar 2008</span></h4>
                                    <p>Universidad Central Del Caribe, San Juan, Puerto Rico</p>
                                    <ul>
                                        <li>Supervised long and short-term plans of action to improve quality of services delivered to patients.</li>
                                        <li>Developed marketing strategies that increased patient volume by more than 80% and annual income by more than 15% in a year.</li>
                                        <li>Conducted clinical research focused on improving results in patients with catastrophic conditions.</li>
                                        <li>Monitored sales and financial resources to achieve revenue goals.</li>
                                        <li>Diversified offer of treatments, including different holistic therapies (Acupuncture, Massage, Chiropractic) supplied to patients.</li>
                                        <li>Redesigned clinic's mission and strategic objectives and achieved outstanding results.</li>
                                        <li>Exercised appropriate management of funds to maximize profitability.</li>
                                    </ul>

                                    <h4>Chinese Medicine Professor <span>Sep 2002 - Nov 2003</span></h4>
                                    <p>Campeche State Health Department, Campeche, Mexico</p>
                                    <ul>
                                        <li>Received invitation to teach Chinese medicine to healthcare professionals.</li>
                                        <li>Delivered individualized instruction on observation, assessment, decision-making and patient health teaching.</li>
                                        <li>Conducted clinical simulations and provided feedback for each individual student.</li>
                                        <li>Participated in continuous improvement by generating suggestions, engaging in problemsolving activities to support teamwork.</li>
                                    </ul>

                                    <h4>Physician <span>Aug 1998 - Aug 2002</span></h4>
                                    <p>Cuban Ballet, Havana, Cuba</p>
                                    <ul>
                                        <li>Developed training plan that reduced dancers' injury rate by more than 60%.</li>
                                        <li>Treated dancers with Traditional Medicine. Achieved great results with spine problems caused by injuries.</li>
                                        <li>Performed healing sessions and provided lifestyle coaching through diet plans and exercises.</li>
                                    </ul>

                                    <h4>Physician <span>Jan 1994</span></h4>
                                    <p>International Center For Neurological Restoration, Havana, Cuba</p>
                                    <ul>
                                        <li>Applied holistic techniques such as Acupuncture, Tui Na Massage, and Joint Manipulations for patients with neurological disorders.</li>
                                        <li>Reduced to minimum therapeutic dose of patients with Parkinson's and epilepsy, which helped lessen side effects.</li>
                                        <li>Reduced recovery time in patients with strokes, improving effects of physical rehabilitation.</li>
                                        <li>Conducted research and developed software solutions to optimize results in patients of refractory evolution.</li>
                                    </ul>

                                    <h3><i className="icofont-certificate-alt-1" />Certifications</h3>
                                    <h4>NCCAOM Oriental Medicine Diplomate <span>May 2022</span></h4>
                                    <h4>Maryland's Board of Acupuncture Licensed <span>May 2022</span></h4>
                                    <h4>Florida's Board of Acupuncture Licensed<span>Jun 2022</span></h4>
                                    <h4>CCAHM Certified<span>Jul 2021</span></h4>

                                    <h3><i className="icofont-hat" />Education</h3>
                                    <h4>Master of Science: Oriental Medicine <span>Abr 2017 - Jan 2021</span></h4>
                                    <p>Acupuncture And Massage College - Miami, FL </p>
                                    <h4>Bachelor of Science: Health Sciences <span>Abr 2017 - Jan 2021</span></h4>
                                    <p>Acupuncture And Massage College - Miami, FL</p>
                                    <h4>Doctor of Medicine <span>Sep 1985 - Aug 1991</span></h4>
                                    <p>Superior Institute of Medical Sciences of Havana - Havana, Cuba</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>   
            </>
        );
    }
}

//Props Types
AboutUsComponent.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    aboutsData: PropTypes.array
};

//Default Props
AboutUsComponent.defaultProps = {
    SectionbgTitle: "About Us",
    sectionTitle: "About Us",
    sectionDescription:
        "Accomplished physician with many years of experience in Oriental Medicine. Excellent reputation for integrating holistic therapies in the treatment of a wide spectrum of patients with different pathologies. Highly proficient in developing education programs, creative, flexible and educated in allopathic and traditional Chinese medicine.",
};

export default AboutUsComponent;
