import React, { Component } from "react";
import PropTypes from "prop-types";

class SuccessSubmit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: "",        
            surnames: "", 
            email: "",
        };
    }

    render() {
        return (
            <section id="contact" className="contact-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <div 
                                className="section-title"
                                data-aos="fade-up"
                                data-aos-delay="30"
                                data-aos-duration="1000"
                            >
                                <h2>{this.props.sectionTitle}</h2>
                                <p>{this.props.sectionDescription}</p>
                                <span className="section-title-bg">{this.props.SectionbgTitle}</span>
                            </div>
                        </div>
                    </div>

                    <div className="row" >
                        <div className="col-lg-6 offset-lg-3 text-center">
                            <div className="contact-form">
                                <div className="button" style={{maxWidth: "100%"}}>
                                    <a href={this.props.link} >{this.props.text} </a>
                                </div>
                            <div className="clearfix" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

//Props Types
SuccessSubmit.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    AddTitle: PropTypes.string,
    Address: PropTypes.string,
    EmailTitle: PropTypes.string,
    Email: PropTypes.string,
    PhoneTitle: PropTypes.string,
    Phone: PropTypes.string,
    link: PropTypes.string,
    text: PropTypes.string
};

//Default Props
SuccessSubmit.defaultProps = {
    SectionbgTitle: "Success",
    sectionTitle: "Success",
    sectionDescription: "You can login using the password sent it to your email",
    link: "/",
    text: "Continue"
};
export default  SuccessSubmit;
