import React, { Component } from 'react';

//Import Component
import NavBar from "../components/NavBar";
import Contact from "../components/Contact";
import Footer from "../components/Footer";

class ContactUs extends Component {

  state = { 
    firstName: '',
    success: false,
    userStatus: '',
  }

  componentDidMount() {
    this.setState({ 
      firstName: localStorage.getItem('firstName'),
      success: localStorage.getItem('success'),
      userStatus: localStorage.getItem('userStatus'),
    });

    if (window.scrollY) {
      window.scroll(0, 0);  // reset the scroll position to the top left of the document.
    }
  }
  
  render() {
    return (
        <>
            {/* NavBar: src/components/NavBar.jsx */}
            <NavBar 
              pageName="contact" 
              firstName={this.state.firstName} 
              success={this.state.success} 
              userStatus={this.state.userStatus}
            />

            {/* LoginInfo: src/components/Contact.jsx */}
            <Contact />

            {/* Footer: src/components/Footer.jsx */}
            <Footer />
        </>
    );
  }
}
export default ContactUs;
