import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";

class GeneralInfoForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: "",
            middleName: "",
            surnames: "",
            dob: "",
            age: "",
            gender: "",
            mobilePhone: "",
            homePhone: "",
            email: "",
            password: "", 
            success: false,         
            successMsg: ""
        };
    }

    //TODO
    componentDidMount() {           
        this.setState({
            firstName: localStorage.getItem('firstName'),
            surnames:  localStorage.getItem('surnames'),
            email:  localStorage.getItem('email'),
        })
    }

    handleForm = e => {
        let that = this;
        axios
            .post("http://localhost:5000/general-info3", this.state )
            .then(function(response) {
                console.log(response);
                if(response.data.success) {
                    that.setState({
                        success: true,
                        successMsg: "Thank you! You are registered!"
                    });
                    localStorage.setItem('registered', true);
                    window.location.href = '/home';
                } else {
                    that.setState({
                        successMsg: "Something went wrong!"
                    });
                }
            })
            .catch(function(error) {
                that.setState({
                    success: false,
                    successMsg: "Somethind went wrong!"
                });
            }
        );
    };

    handleFields = e => this.setState({ [e.target.name]: e.target.value });
    
    render() {
        return (
            <>
                <section id="contact" className="contact-area ptb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2 text-center">
                                <div 
                                    className="section-title"
                                    data-aos="fade-up"
                                    data-aos-delay="30"
                                    data-aos-duration="1000"
                                >
                                    <h2>{this.props.sectionTitle}</h2>
                                    <p>{this.props.sectionDescription}</p>
                                    <span className="section-title-bg">{this.props.SectionbgTitle}</span>
                                </div>
                            </div>
                        </div>

                        <div className="row" >
                            <div className="col-lg-6 offset-lg-3 text-center">
                                <div className="contact-form">
                                    <form
                                        id="contactForm"
                                        onSubmit={(e, formData, inputs) => {
                                            e.preventDefault();
                                            this.handleForm(formData);
                                        }}
                                    >
                                        <div className="">
                                            <div className="row pt-4 border">
                                                <h3 className="text-center">General Information</h3>
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <input
                                                            name="firstName"
                                                            id="firstName"
                                                            value={this.state.firstName}
                                                            required
                                                            readOnly
                                                            className="form-control"
                                                            placeholder="First Name"
                                                            onChange={
                                                                this.handleFields
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <input
                                                            name="middleName"
                                                            id="middleName"
                                                            
                                                            className="form-control"
                                                            placeholder="Middle Name"
                                                            onChange={
                                                                this.handleFields
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <input
                                                            name="surnames"
                                                            id="surnames"
                                                            defaultValue={this.state.surnames}
                                                            required
                                                            readOnly
                                                            className="form-control"
                                                            placeholder="Surnames"
                                                            onChange={
                                                                this.handleFields
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4">
                                                    <div className="form-group">
                                                        <input
                                                            name="dob"
                                                            id="dob"
                                                            required
                                                            type="date"
                                                            className="form-control"
                                                            placeholder="Date of Birth"
                                                            onChange={
                                                                this.handleFields
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                           
                                                <div className="col-lg-4 col-md-4">
                                                    <div className="form-group">
                                                        <input
                                                            name="age"
                                                            id="age"
                                                            required
                                                            className="form-control"
                                                            placeholder="Age"
                                                            onChange={
                                                                this.handleFields
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4">
                                                    <div className="form-group">
                                                        <select 
                                                            name="gender"
                                                            id="gender"
                                                            required
                                                            className="form-select"
                                                            onChange={
                                                                this.handleFields
                                                            }
                                                        >
                                                            <option value="">Gender</option>
                                                            <option value="1">Male</option>
                                                            <option value="2">Female</option>
                                                            <option value="3">Prefer not to say</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4">
                                                    <div className="form-group">
                                                        <input
                                                            name="mobilePhone"
                                                            id="mobilePhone"
                                                            type="text" 
                                                            className="form-control"
                                                            placeholder="Mobile Phone"
                                                            onChange={
                                                                this.handleFields
                                                            }
                                                        />
                                                    </div>
                                                </div>   
                                                <div className="col-lg-4 col-md-3">
                                                    <div className="form-group">
                                                        <input
                                                            name="homePhone"
                                                            id="homePhone"
                                                            type="text" 
                                                            className="form-control"
                                                            placeholder="Home Phone"
                                                            onChange={
                                                                this.handleFields
                                                            }
                                                        />
                                                    </div>
                                                </div>                  

                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <input
                                                            name="email"
                                                            id="email"
                                                            defaultValue={this.state.email}
                                                            type="email"
                                                            required
                                                            readOnly
                                                            className="form-control"
                                                            placeholder="Email"
                                                            onChange={
                                                                this.handleFields
                                                            }
                                                        />
                                                    </div>
                                                </div>                                                 
                                            </div>
                                        </div>

                                        <div className="center-wrap mt-4" >
                                            <div className="button ">
                                                <button type="submit">Submit <i className="icofont-long-arrow-right"/> </button>
                                                <div className="mask"></div>
                                            </div>
                                        </div>                                        

                                        <div className="clearfix" />
                                    </form>

                                    {this.state.successMsg !== "" ? (
                                        <div className="col-md-12"> 
                                            <div id="contact_send_status">
                                                <h3 className="contactMsg">
                                                    {this.state.successMsg}
                                                </h3>
                                            </div>
                                         </div>
                                    ) : null}

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

//Props Types
GeneralInfoForm.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    AddTitle: PropTypes.string,
    Address: PropTypes.string,
    EmailTitle: PropTypes.string,
    Email: PropTypes.string,
    PhoneTitle: PropTypes.string,
    Phone: PropTypes.string,
};

//Default Props
GeneralInfoForm.defaultProps = {
    SectionbgTitle: "Registration",
    sectionTitle: "Patient Registration Form",
    sectionDescription: "Please enter your general information to register.",
    AddTitle: "Address",
    Address: "2750 Quadra Street Victoria, Canada.",
    EmailTitle: "Email",
    Email: "neningerorientalmedicine@gmail.com",
    PhoneTitle: "Phone",
    Phone: "+1-325-555-0156",
};
export default GeneralInfoForm;
