import React, { Component } from 'react';

//Import Component
import NavBar from "../components/NavBar";
import LoginInfo from "../components/LoginInfo";
import Footer from "../components/Footer";

class PatientLogin extends Component {

  state = { 
    usename: '',
    success: false,
    registered: false,
  }

  componentDidMount() {
      this.setState({ 
        firstName: localStorage.getItem('firstName'),
        success: localStorage.getItem('success'),
        userStatus: localStorage.getItem('userStatus'),
       });
  }

  render() {
    return (
        <>
            {/* NavBar: src/components/NavBar.jsx */}
            <NavBar 
              pageName="home" 
              firstName={this.state.firstName} 
              success={this.state.success} 
              userStatus={this.state.userStatus}
            />

            {/* LoginInfo: src/components/LoginInfo.jsx */}
            <LoginInfo />

            {/* Footer: src/components/Footer.jsx */}
            <Footer />
        </>
    );
  }
}
export default PatientLogin;
