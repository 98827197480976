import React, { Component } from "react";
// import {  Redirect } from "react-router-dom"
import PropTypes from "prop-types";
import axios from "axios";

import SuccessSubmit from "./SuccessSubmit"


class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: "",        
            surnames: "", 
            email: "",
            validFirstName: false,
            validSurnames: false,
            validEmail: false,

            success: false,       
            successMsg: ""
        };
    }

    handleForm = e => {
        let that = this;
        axios
            .post("http://localhost:5000/register3", this.state )
            .then(function(response) {
                console.log(response.data.message)
                if(response.data.userStatus === "exist") {
                    document.getElementById("contactForm").reset();
                    that.setState({
                        successMsg: response.data.message,
                        success: true,
                        validFirstName: true,
                        validSurnames: true,
                        validEmail: true,
                    });
                    document.getElementById("contactForm").reset(); 
                } else {
                    that.setState({
                        successMsg: "Something went wrong!", //"Thank you! We received your message",
                        success: true,
                        validFirstName: true,
                        validSurnames: true,
                        validEmail: true,
                    });
                }
                

            })
            .catch(function(error) {

            });
    };

    handleFields = e => this.setState({ [e.target.name]: e.target.value });
    
    render() {
        return (
            <> {this.state.success ? (
                <section>
                    <SuccessSubmit link={"/login"} text={"Continue"} sectionDescription={"Please login using the password sent to your email."}/>
                </section>
            ) : (
                <section id="contact" className="contact-area ptb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2 text-center">
                                <div 
                                    className="section-title"
                                    data-aos="fade-up"
                                    data-aos-delay="30"
                                    data-aos-duration="1000"
                                >
                                    <h2>{this.props.sectionTitle}</h2>
                                    <p>{this.props.sectionDescription}</p>
                                    <span className="section-title-bg">{this.props.SectionbgTitle}</span>
                                </div>
                            </div>
                        </div>

                        <div className="row" >
                            <div className="col-lg-6 offset-lg-3 text-center">
                                <div className="contact-form">
                                    <form
                                        id="contactForm"
                                        onSubmit={(e, formData, inputs) => {
                                            e.preventDefault();
                                            this.handleForm(formData);
                                        }}
                                    >
                                        <div className="row">
                                        <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <input
                                                        name="firstName"
                                                        id="first-name"
                                                        required
                                                        type="text"
                                                        autoComplete="off"
                                                        className="form-control"
                                                        placeholder="First Name"
                                                        onChange={
                                                            this.handleFields
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <input
                                                        name="surnames"
                                                        id="surnames"
                                                        type="text"
                                                        autoComplete="off"
                                                        required
                                                        className="form-control"
                                                        placeholder="Surnames"
                                                        onChange={
                                                            this.handleFields
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <input
                                                        name="email"
                                                        id="email"
                                                        type="email"
                                                        autoComplete="off"
                                                        required
                                                        className="form-control"
                                                        placeholder="email"
                                                        onChange={
                                                            this.handleFields
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="center-wrap">
                                            <div className="button">
                                                <button 
                                                    type="submit"
                                                    >Submit <i className="icofont-long-arrow-right"/>
                                                    </button>
                                                <div className="mask"></div>
                                            </div>
                                        </div>
                                        
                                        <div className="clearfix" />
                                    </form>
                                    {this.state.successMsg !== "" ? (
                                        <div className="col-md-12"> 
                                            <div id="contact_send_status">
                                                <h3 className="contactMsg">
                                                    {this.state.successMsg}
                                                </h3>
                                            </div>
                                         </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}</>
        );
    }
}

//Props Types
Register.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
};

//Default Props
Register.defaultProps = {
    SectionbgTitle: "Register",
    sectionTitle: "Register",
    sectionDescription: "",
};
export default  Register;
