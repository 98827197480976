import React, { Component } from 'react';

//Import Component
import NavBar from "../components/NavBar";
import RegisterInfo from "../components/RegisterInfo";
import Footer from "../components/Footer";

class Register extends Component {
  state = { 
    usename: '',
    success: false,
    registered: false,
  }

  componentDidMount() {
    this.setState({ 
      username: localStorage.getItem('username'),
      success: localStorage.getItem('success'),
      registered: localStorage.getItem('registered')
    });
  }

  render() {
    return (
        <>
            {/* NavBar: src/components/NavBar.jsx */}
            <NavBar 
              pageName="home" 
              username={this.state.username} 
              success={this.state.success} 
              registered={this.state.registered}
            />

            {/* LoginInfo: src/components/Register.jsx */}
            <RegisterInfo />

            {/* Footer: src/components/Footer.jsx */}
            <Footer />
        </>
    );
  }
}
export default Register;
